/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { Heading, Layout, PortableText, ProtectedImage } from "components";

const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
};

function AnnouncementPage({
  data: {
    sanityAnnouncement: { title, content, image },
  },
}) {
  return (
    <Layout pageTitle={title}>
      <Heading mb="m" sx={visuallyHidden}>
        {title}
      </Heading>

      {image?.asset?.gatsbyImageData && (
        <ProtectedImage
          image={image.asset.gatsbyImageData}
          alt={title}
          sx={{
            ml: [null, "m"],
            mb: "m",
            float: [null, "right"],
            width: [null, 400, 600],
          }}
        />
      )}

      {content && <PortableText content={content} />}
    </Layout>
  );
}

export const query = graphql`
  query Announcement($id: String) {
    sanityAnnouncement(_id: { eq: $id }) {
      image {
        asset {
          gatsbyImageData(width: 686)
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      title
    }
  }
`;

export default AnnouncementPage;
